import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cmt-footer',
  templateUrl: './cmt-footer.component.html',
  styleUrls: ['./cmt-footer.component.scss']
})
export class CmtFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
