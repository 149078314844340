import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ClientService } from 'src/Common/Services/client.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CmtCustomerService } from 'src/Common/Services/cmt-customer.service';
import { ToastrService } from 'ngx-toastr';
import * as mockConstants from '../../Common/JSON/cmt-constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cmt-header',
  templateUrl: './cmt-header.component.html',
  styleUrls: ['./cmt-header.component.scss']
})
export class CmtHeaderComponent implements OnInit, OnDestroy {
  // User Details
  userName: any = '';
  firstName: any = '';
  lastName: any = '';
  roleId: any = '';
  backgroundImg;

  // For unscription
  private ngUnsubscribe: Subject<any> = new Subject();

  customerDetailsForm: FormGroup;

  // Customer Modal //
  customerModalRef: BsModalRef;
  @ViewChild('customerDetailModal') customerDetailModal: TemplateRef<any>;

  customerListModalRef: BsModalRef;
  @ViewChild('customersListModal') customersListModal: TemplateRef<any>;

  customerDeleteModalRef: BsModalRef;
  @ViewChild('deleteCustomerModal') deleteCustomerModal: TemplateRef<any>;

  availablePlatformsList = [
    { id: 'aws', itemName: 'AWS' },
    { id: 'azure', itemName: 'Azure' },
    { id: 'gcp', itemName: 'GCP' },
    { id: 'oracle', itemName: 'Oracle' }
  ];

  platformsList = ['aws', 'azure', 'gcp', 'oracle'];

  selectedItems = [];

  settings1 = {
    text: 'Select Platform',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    singleSelection: true
  };

  settings2 = {
    text: 'Select Platforms',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    classes: 'myclass custom-class',
    badgeShowLimit: 3
  };

  customerGridColumns: any = [];
  isCustomerListLoading: any = false;
  customerRowsList: any = [];
  noCustomerData: any = false;
  reduceOpacity: any = false;
  customerId: any = '';
  rowCustomerId: any = '';
  customerAction: any = '';
  userLoggedInDetails: any = {};

  // User Profile Modal //
  userProfileModalRef: BsModalRef;
  @ViewChild('userProfileModal') userProfileModal: TemplateRef<any>;

  constructor(
    private clientService: ClientService,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private customerService: CmtCustomerService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this.clientService.userDetails.pipe(takeUntil(this.ngUnsubscribe)).subscribe(userDetails => {
      this.userLoggedInDetails = userDetails;
      this.userName = userDetails.userFirstName;
      this.roleId = userDetails.role_id;
      this.customerId = userDetails.customerId;
      this.firstName = this.userName.split(' ')[0];
      this.lastName = this.userName.split(' ')[1];
    });
    // From Session storage
    if (!this.userName.length) {
      const storageData = JSON.parse(sessionStorage.getItem('loggedInDetails'));
      if (storageData) {
        this.roleId = storageData.role_id;
        this.customerId = storageData.customerId;
        this.userName = storageData.userFirstName;
        this.userLoggedInDetails = storageData;
        this.firstName = this.userName.split(' ')[0];
        this.lastName = this.userName.split(' ')[1];
      }
    }
  }

  ngOnInit(): void {
    const imageUrl = '../../assets/images/poorva.png';
    const altBackgroudImage = '../../assets/images/icons/User_icon.svg';
    // this.backgroundImg = this.sanitizer.bypassSecurityTrustStyle(`url('${imageUrl}'), url('${altBackgroudImage}')`
    this.backgroundImg = this.sanitizer.bypassSecurityTrustStyle(`url('${altBackgroudImage}')`
    );

    this.customerDetailsForm = this.formBuilder.group({
      customerName: ['', [Validators.required]],
      customerContact: ['', [Validators.required]],
      preferredHypervisor: [''],
      customerCode: [''],
      multiCloud: [''],
      platforms: [[]]
    });
  }

  // Camel Case logic
  camelize(str) {
    return str.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  // On Customer List Modal Popup Open Function //
  openCustomersList() {
    this.customerRowsList = [];
    this.noCustomerData = false;
    this.customerListModalRef = this.modalService.show(this.customersListModal, {
      class: 'customersListModalCss modal-dialog-centered', ignoreBackdropClick: true, animated: true,
      keyboard: false, backdrop: true
    });
    this.customerGridColumns = mockConstants.customerListColumns;
    this.isCustomerListLoading = true;
    this.customerService.getCUstomerDetailsList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
      if (resp.statusMessage === 'SUCCESS') {
        this.isCustomerListLoading = false;
        this.reduceOpacity = false;
        this.customerRowsList = resp.responseData;
      } else {
        this.toastr.error('Error while fetching customer list.');
      }
      if (!resp.responseData.length) {
        this.noCustomerData = true;
      }
    });
  }

  // On Customer Grid Column Sort Function //
  onCustomerListGridColumnSort(column: any) {

  }

  // OnCustomer Grid S roll Function //
  customerListOnScroll(event) {
  }

  // On Customer Details Modal Popup Open Function //
  openCustomerModal(action) {
    this.selectedItems = [];
    this.customerAction = action;
    this.customerDetailsForm.reset();
    this.customerModalRef = this.modalService.show(this.customerDetailModal, {
      class: 'customerModalCss modal-dialog-centered', ignoreBackdropClick: true, animated: true,
      keyboard: false, backdrop: true
    });
    this.customerDetailsForm.controls.customerName.enable();
    this.reduceOpacity = true;
  }

  // On Clear Customer Details Form Function //
  onCustomerDetailsClear() {
    this.customerDetailsForm.reset();
  }

  // On Multi Cloud Change Event Function //
  onMultiCloudChange(event) {
    if (event.target.value) {
      this.customerDetailsForm.controls.platforms.setValue([]);
      this.customerDetailsForm.controls.platforms.setValidators([Validators.required]);
    }
    this.customerDetailsForm.controls.preferredHypervisor.setValue('');
    if (event.target.value === 'Y') {
      this.customerDetailsForm.controls.preferredHypervisor.setValidators([Validators.required]);
      this.customerDetailsForm.controls.preferredHypervisor.updateValueAndValidity();
    } else {
      this.customerDetailsForm.controls.preferredHypervisor.clearValidators();
      this.customerDetailsForm.controls.preferredHypervisor.updateValueAndValidity();
    }
  }

  // On Submit Customer Details Function //
  onCustomerDetailsSubmit() {
    const request = {
      customerName: this.customerDetailsForm.controls.customerName.value,
      customerEmail: this.customerDetailsForm.controls.customerContact.value,
      preferredHypervisor: this.customerDetailsForm.controls.preferredHypervisor.value,
      loggedInUserEmail: this.userLoggedInDetails.userEmail,
      multiCloudFlag: false,
      aws: false,
      azure: false,
      gcp: false,
      oracle: false
    };
    const platforms = this.customerDetailsForm.controls.platforms.value.map(item => item.id);
    platforms.map(item => {
      if (this.platformsList.includes(item)) {
        request[item] = true;
      }
    });
    if (this.customerDetailsForm.controls.multiCloud.value === 'Y') {
      request.multiCloudFlag = true;
    } else {
      request.multiCloudFlag = false;
      request.preferredHypervisor = platforms[0];
    }
    this.customerModalRef.hide();
    this.customerService.addOrUpdateCustomerDetails(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
      if (resp.statusMessage === 'SUCCESS') {
        this.toastr.success(resp.responseData);
        this.reduceOpacity = false;
        this.isCustomerListLoading = true;
        // Fetch Customer List After adding customer //
        this.customerService.getCUstomerDetailsList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          if (data.statusMessage === 'SUCCESS') {
            this.isCustomerListLoading = false;
            this.reduceOpacity = false;
            this.customerRowsList = data.responseData;
          } else {
            this.toastr.error('Error while fetching customer list.');
          }
          if (!data.responseData.length) {
            this.noCustomerData = true;
          }
        });
      } else {
        this.toastr.error('Error while adding customer details');
      }
    });
  }

  // On Close Customer Details Function //
  closeCustomerDetailModal() {
    this.customerModalRef.hide();
    setTimeout(() => {
      this.reduceOpacity = false;
    }, 500);
  }

  // On Edit Customer Details Function //
  editCustomerDetails(customer, action) {
    this.selectedItems = [];
    this.customerAction = '';
    this.customerAction = action;
    this.rowCustomerId = customer.customerId;
    this.customerDetailsForm.controls.customerName.setValue(customer.customerName);
    this.customerDetailsForm.controls.customerName.disable();
    this.customerDetailsForm.controls.customerContact.setValue(customer.customerContact);
    this.customerDetailsForm.controls.customerCode.setValue(customer.customerCode);
    this.customerDetailsForm.controls.preferredHypervisor.setValue(customer.hypervisor);
    this.customerDetailsForm.controls.customerCode.disable();
    if (customer.isMultiCloud === true) {
      this.customerDetailsForm.controls.multiCloud.setValue('Y');
    }
    if (customer.isMultiCloud === false) {
      this.customerDetailsForm.controls.multiCloud.setValue('N');
    }
    if (this.customerDetailsForm.controls.multiCloud.value === 'Y' || this.customerDetailsForm.controls.multiCloud.value === 'N') {
      this.customerDetailsForm.controls.platforms.setValidators([Validators.required, Validators.minLength(1)]);
    }
    let platforms = [];
    if (customer.aws) {
      const obj = { id: 'aws', itemName: 'AWS' };
      platforms.push('aws');
      this.selectedItems.push(obj);
    }
    if (customer.azure) {
      const obj = { id: 'azure', itemName: 'Azure' };
      platforms.push('azure');
      this.selectedItems.push(obj);
    }
    if (customer.gcp) {
      const obj = { id: 'gcp', itemName: 'GCP' };
      platforms.push('gcp');
      this.selectedItems.push(obj);
    }
    if (customer.oracle) {
      const obj = { id: 'oracle', itemName: 'Oracle' };
      platforms.push('oracle');
      this.selectedItems.push(obj);
    }
    platforms = [...new Set(platforms)];
    this.customerDetailsForm.controls.platforms.setValue(platforms);
    this.customerDetailsForm.controls.platforms.updateValueAndValidity();
    this.customerModalRef = this.modalService.show(this.customerDetailModal, {
      class: 'customerModalCss modal-dialog-centered', ignoreBackdropClick: true, animated: true,
      keyboard: false, backdrop: true
    });
    this.reduceOpacity = true;
  }

  // On Customer Details Update Function //
  onCustomerDetailsUpdate() {
    const request = {
      custId: this.rowCustomerId,
      customerName: this.customerDetailsForm.controls.customerName.value,
      customerEmail: this.customerDetailsForm.controls.customerContact.value,
      preferredHypervisor: this.customerDetailsForm.controls.preferredHypervisor.value,
      loggedInUserEmail: this.userLoggedInDetails.userEmail,
      multiCloudFlag: false,
      aws: false,
      azure: false,
      gcp: false,
      oracle: false
    };

    const platforms = this.customerDetailsForm.controls.platforms.value.map(item => item.id);
    platforms.map(item => {
      if (this.platformsList.includes(item)) {
        request[item] = true;
      }
    });
    if (this.customerDetailsForm.controls.multiCloud.value === 'Y') {
      request.multiCloudFlag = true;
    } else {
      request.multiCloudFlag = false;
      request.preferredHypervisor = platforms[0];
    }
    this.customerModalRef.hide();
    this.customerService.addOrUpdateCustomerDetails(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
      this.selectedItems = [];
      if (resp.statusMessage === 'SUCCESS') {
        this.toastr.success(resp.responseData);
        this.reduceOpacity = false;
        const index = this.customerRowsList.findIndex(item => {
          return item.customerId === this.rowCustomerId;
        });
        this.customerRowsList[index].customerName = request.customerName;
        this.customerRowsList[index].customerContact = request.customerEmail;
        this.customerRowsList[index].isMultiCloud = request.multiCloudFlag;
        this.customerRowsList[index].aws = request.aws;
        this.customerRowsList[index].azure = request.azure;
        this.customerRowsList[index].gcp = request.gcp;
        this.customerRowsList[index].oracle = request.oracle;
        this.customerRowsList[index].hypervisor = request.preferredHypervisor;
      } else {
        this.toastr.error('Error while updating customer details');
      }
    });
  }

  // On Customer Delete Confirmation Function //
  confirmDelete(customerId) {
    this.customerId = '';
    this.rowCustomerId = customerId;
    this.customerDeleteModalRef = this.modalService.show(this.deleteCustomerModal, {
      class: 'customerDeleteModalCss modal-sm modal-dialog-centered', ignoreBackdropClick: true, animated: true,
      keyboard: false, backdrop: true
    });
    this.reduceOpacity = true;
  }

  // On Customer Delete Cancel Function //
  closeDeleteModal() {
    this.customerDeleteModalRef.hide();
    setTimeout(() => {
      this.reduceOpacity = false;
    }, 500);
  }

  // On Customer Delete Click Function //
  onCustomerDelete() {
    this.customerDeleteModalRef.hide();
    this.isCustomerListLoading = true;
    const payload = {
      custId: this.rowCustomerId
    };
    this.customerService.deleteCustomer(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
      if (resp.statusMessage === 'SUCCESS') {
        this.toastr.success(resp.responseData);
        const index = this.customerRowsList.findIndex(item => {
          return item.customerId === this.rowCustomerId;
        });
        this.customerRowsList.splice(index, 1);
        this.isCustomerListLoading = false;
        this.reduceOpacity = false;
      } else {
        this.toastr.success('Error while deleting the user');
      }
    });
  }

  // User Profile Click Function //
  openUserProfileModal() {
    this.userProfileModalRef = this.modalService.show(this.userProfileModal, {
      class: 'customerModalCss modal-dialog-centered', ignoreBackdropClick: true, animated: true,
      keyboard: false, backdrop: true
    });
  }

  // On Logout Click //
  logout() {
    this.modalService.hide(1);
    window.sessionStorage.clear();
    this.router.navigate(['/signup']);
  }

  ngOnDestroy() {
    // Close all modals //
    this.modalService.hide(1);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
