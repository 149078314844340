import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, tap, map } from 'rxjs/operators';
import { AppUtilityService } from './app-utility.service';

@Injectable({
  providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {
  baseUrl;
  constructor(private appUtilityService: AppUtilityService) {

    if (window.location.origin.includes('localhost')) {
      // this.baseUrl = 'http://localhost:8812/cmtservice/rest/';
      this.baseUrl = 'http://localhost:8085/';
    } else {
      // this.baseUrl = 'http://18.223.238.241:8812/cmtservice/rest/';
      // this.baseUrl = window.location.origin + '/';
      this.baseUrl = 'https://iestimate.genpact.com' + '/';
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers;
    let clone;

    headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // For login - change headers
    if (req.url.toLowerCase().includes('changepwd')) {
      headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
    }
    clone = req.clone({
      url: this.baseUrl + req.url,
      headers,
     // body: this.appUtilityService.encryptUsingAES256(req.body) // Encrypt Payload
    });

    return next.handle(clone)
      .pipe(
        map(resp => {
          if (resp instanceof HttpResponse) {
            return resp.clone({
            //  body: JSON.parse(this.appUtilityService.decryptUsingAES256(resp.body.value)) // Decrypt received response
            });
          }
        }),
        catchError(this.handleError)
      );
  }

  // Handling HTTP errors
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
