import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxFileDropModule } from 'ngx-file-drop';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more.src';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AppInterceptorService } from './Services/app-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng5SliderModule } from 'ng5-slider';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    NgxDatatableModule,
    NgxFileDropModule,
    HighchartsChartModule,
    ChartModule,
    ProgressbarModule.forRoot(),
    NgCircleProgressModule.forRoot({}),
    Ng5SliderModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' })
  ],
  exports: [
    FormsModule,
    PopoverModule,
    NgxDatatableModule,
    NgxFileDropModule,
    ModalModule,
    ToastrModule,
    HighchartsChartModule,
    ProgressbarModule,
    NgCircleProgressModule,
    Ng5SliderModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [HighchartsMore, HighchartsSolidGauge]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    }
  ]
})
export class SharedModule { }
