import { Injectable } from '@angular/core';
// export to excel
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
const EXCEL_TYPE =
  'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  fetchAssessmentData: any;
  fetchAssessAndEvaluateData: any;

  // Login deatails
  collectUserDetails: any;
  userDetails: any = new Subject<any>();

  constructor(private http: HttpClient) { }

  // Capture login details
  captureLoginDetails(details) {
    this.userDetails.next(details);
  }

  // Set the user details
  setUserDetails(userD: any): void {
    this.collectUserDetails = userD;
  }

  // Get and set assessment details
  setAssessmentData(data: any) {
    this.fetchAssessmentData = data;
  }

  // Get and set Assess And EvaluateData details
  setAssessAndEvaluateData(data: any) {
    this.fetchAssessAndEvaluateData = data;
  }

  // Excel Export //
  exportAsExcelFile(data: any[], excelFileName: string, headersArray: any[], keys): Observable<any> {
    const arr = [];
    data.map(res => {
      const keyRow = [];
      keys.map(key => {
        keyRow.push(res[key]);
      });
      if (keyRow.length === keys.length) {
        arr.push(keyRow);
      }
    });
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(excelFileName);

    // Add Header Row
    const header = headersArray;
    const headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        bold: true,
        fgColor: { argb: 'c0d1dd' },
        bgColor: { argb: 'c0d1dd' }
      };
      cell.font = { name: 'Calibri', family: 4, size: 11, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    if (!arr.length) {
      console.log('No data in excel sheet');
      headersArray.forEach((headers, i) => {
        worksheet.getColumn(i + 1).width = 20 + headers.length;
      });
    }
    // Add Data and Conditional Formatting

    arr.forEach(element => {
      const eachRow = [];
      headersArray.forEach((headers, i) => {
        worksheet.getColumn(i + 1).width = 20 + headers.length;
        eachRow.push(element[headers]);
      });
      worksheet.addRow(element);
    });

    workbook.xlsx.writeBuffer().then(resp => {
      const blob = new Blob([resp], { type: EXCEL_TYPE });
      let fileName = '';
      const date = new Date().toLocaleDateString();
      fileName = excelFileName + EXCEL_EXTENSION;
      FileSaver.saveAs(
        blob,
        fileName
      );
    });
    return of('success');
  }

  // Get Side Navbar Details API //
  getSideNavBarDetails(patload: any): Observable<any> {
    return this.http.post('customersetup/getsidenav', patload);
  }

  // Change Password API //s
  changePassword(payload: any): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        existingPwd: payload.existingPwd,
        newPwd: payload.newPwd,
        emailAddress: payload.emailAddress
      }
    });
    return this.http.post('customersetup/changepwd', params);
  }

  checkAssessmentValidity(payload: any): Observable<any> {
    return this.http.post('validation', payload);
  }
}
