import { Component, OnInit, OnDestroy } from '@angular/core';
import * as mockConstants from '../../Common/JSON/cmt-constants';
import { ClientService } from 'src/Common/Services/client.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cmt-side-navbar',
  templateUrl: './cmt-side-navbar.component.html',
  styleUrls: ['./cmt-side-navbar.component.scss']
})
export class CmtSideNavbarComponent implements OnInit, OnDestroy {
  sideNavBarData: any = [];
  sideNavBarSettingsData: any = [];
  openSubMenuFlag: any = true;

  // Login Details
  userLoginDetails: any = {};

  // For unscription
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private clientService: ClientService) { }

  ngOnInit(): void {
    this.clientService.userDetails.subscribe(userData => {
      this.userLoginDetails = userData;
      this.getSideNavDetails();
    });
    /*
    if (Object.keys(this.userLoginDetails).length === 0) {
      this.userLoginDetails = JSON.parse(sessionStorage.getItem('loggedInDetails'));
      this.getSideNavDetails();
    }
    */
    // Get Sidebar menus from mockconstants file
    // this.sideNavBarData = mockConstants.sideMenus[0].mainMenu;
    this.sideNavBarSettingsData = mockConstants.sideMenus[0].settingsMenu;
  }

  getSideNavDetails() {
    const payload = {
      userEmail: this.userLoginDetails.userEmail,
      roleId: this.userLoginDetails.role_id
    };
    this.clientService.getSideNavBarDetails(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resp => {
      resp.responseData = resp.responseData.filter(item => {
        if (item.pageName !== 'Tools' && item.pageName !== 'Customers') {
          return item;
        }
      });

      resp.responseData.sort((item1, item2) => {
        return item1.pageId - item2.pageId;
      });
      this.sideNavBarData = resp.responseData;
    });
  }

  // On Menu click sidenav
  onClickSideNavMenu() {
    this.clientService.setAssessmentData(null);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
