// Application SideMenu bar details
export const sideMenus = [{
  mainMenu: [{
    pageName: 'Upload App Inventory',
    path: 'app-inventory',
    disabled: false
  },
  {
    pageName: 'Assessment',
    path: 'assessment',
    disabled: false
  },
  {
    pageName: 'Report Out',
    path: 'report-out',
    disabled: false
  },
  // {
  //     pageName: 'Cost Calculation',
  //     path: 'cost-calculation',
  //     disabled: false
  // },
  // {
  //     pageName: 'Effort Estimation',
  //     path: 'effort-estimation',
  //     disabled: false
  // },
  {
    pageName: 'Planning',
    path: 'plan',
    disabled: false
  },
  {
    pageName: 'Execution Tracking',
    path: 'execution-tracking',
    disabled: false
  },
  {
    pageName: 'Billing',
    path: 'billing',
    disabled: false
  }],
  settingsMenu: [
    {
      pageName: 'Settings',
      id: 'cmt_settings',
      path: 'cmt_settings',
      disabled: false,
      subMenu: [{
        pageName: 'Customer',
        path: 'customer',
        id: 'cmt_custLvl',
        disabled: false
      },
      {
        pageName: 'Tool',
        path: 'tool',
        id: 'cmt_toolLvl',
        disabled: false
      }]
    }]

}];

// App Inventory Grid column list
export const appInventoryGridColumnNames = [
  {
    name: 'Assessment Name',
    title: 'Assessment Name',
    columnKey: 'assessment_name',
    key: 'assessment_name',
    width: 180
  },
  {
    name: 'Creation Date',
    title: 'Creation Date',
    columnKey: 'creation_date',
    key: 'creation_date',
    width: 140
  },
  {
    name: 'Created By',
    title: 'Created By',
    columnKey: 'created_by',
    key: 'created_by',
    width: 180
  },
  {
    name: 'Total Apps',
    title: 'Total Apps',
    columnKey: 'no_of_app',
    key: 'no_of_app',
    width: 130
  },
  {
    name: 'Current Infra Cost',
    title: 'Current Infra Cost',
    columnKey: 'infra_cost',
    key: 'infra_cost',
    width: 180
  },
  {
    name: 'View',
    title: 'View',
    columnKey: 'view',
    key: 'view',
    width: 80
  },
  {
    name: 'Delete',
    title: 'Delete',
    columnKey: 'delete',
    key: 'delete',
    width: 80
  }];

// Assessment Grid column list
export const assessmentGridColumnNames = [
  {
    name: 'Action',
    title: 'Action',
    columnKey: 'action',
    key: 'action',
    width: 80
  },
  {
    name: 'Customer Name',
    title: 'Customer Name',
    columnKey: 'customer_name',
    key: 'customer_name',
    width: 190
  },
  {
    name: 'Application Name',
    title: 'Application Name',
    columnKey: 'app_name',
    key: 'app_name',
    width: 190
  },
  {
    name: 'Application Id',
    title: 'Application Id',
    columnKey: 'app_id',
    key: 'app_id',
    width: 190
  },
  {
    name: 'Application Criticality',
    title: 'Application Criticality',
    columnKey: 'app_criticality',
    key: 'app_criticality',
    width: 220
  },
  {
    name: 'Application Type',
    title: 'Application Type',
    columnKey: 'app_type',
    key: 'app_type',
    width: 190
  },
  {
    name: 'Already On Cloud',
    title: 'Already On Cloud',
    columnKey: 'already_on_cloud',
    key: 'already_on_cloud',
    width: 190
  },
  {
    name: 'Active Users Per Month',
    title: 'Active Users Per Month',
    columnKey: 'no_of_users',
    key: 'no_of_users',
    width: 240
  },
  {
    name: 'Environments',
    title: 'Environments',
    columnKey: 'no_of_environment',
    key: 'no_of_environment',
    width: 190
  },
  {
    name: 'Business Plan',
    title: 'Business Plan',
    columnKey: 'business_plan',
    key: 'business_plan',
    width: 190
  }
];

// Report Out Grid column list
export const reportOutGridColumnNames = [
  {
    name: 'Application ID',
    title: 'Application ID',
    columnKey: 'app_id',
    key: 'app_id',
    width: 105
  }, {
    name: 'Application Name',
    title: 'Application Name',
    columnKey: 'app_name',
    key: 'app_name',
    width: 130
  },
  {
    name: 'Cloud Fitment',
    title: 'Cloud Fitment',
    columnKey: 'cloud_ready',
    key: 'cloud_ready',
    width: 105
  },
  {
    name: 'Cloud Bucket',
    title: 'Cloud Bucket',
    columnKey: 'application_bucket',
    key: 'application_bucket',
    width: 105
  },
  {
    name: 'Domain',
    title: 'Domain',
    columnKey: 'functional_domain',
    key: 'functional_domain',
    width: 140
  },
  {
    name: 'Existing Cost',
    title: 'Existing Cost',
    columnKey: 'previous_infra_cost',
    key: 'previous_infra_cost',
    width: 110
  },
  {
    name: 'To Be Cost',
    title: 'To Be Cost',
    columnKey: 'total_cost',
    key: 'total_cost',
    width: 110
  },
  {
    name: 'Infra Savings',
    title: 'Infra Savings',
    columnKey: 'cost_difference',
    key: 'cost_difference',
    width: 120
  },
  {
    name: 'Functional Benefits',
    title: 'Functional Benefits',
    columnKey: 'functional_savings',
    key: 'functional_savings',
    width: 140
  },
  {
    name: 'Total Savings',
    title: 'Total Savings',
    columnKey: 'total_savings',
    key: 'total_savings',
    width: 120
  },
  {
    name: 'Criticality',
    title: 'Criticality',
    columnKey: 'app_critical',
    key: 'app_critical',
    width: 80
  },
  {
    name: 'Infra Scale',
    title: 'Infra Scale',
    columnKey: 'infrastructure_type',
    key: 'infrastructure_type',
    width: 100
  },
  {
    name: '',
    title: '',
    columnKey: 'app_component',
    key: 'app_component',
    width: 160
  }];

// Report Out Grid column list
export const reportOutServiceListColumnNames = [
  // {
  //     name: 'Service Name',
  //     title: 'Service Name',
  //     columnKey: 'serviceName',
  //     key: 'serviceName',
  //     width: 120,
  // },
  // {
  //     name: 'Price',
  //     title: 'Price',
  //     columnKey: 'price',
  //     key: 'price',
  //     width: 100,
  // },
  {
    name: 'Instance Type',
    title: 'Instance Type',
    columnKey: 'instance_type',
    key: 'instance_type',
    width: 120,
  },
  {
    name: 'Operating System',
    title: 'Operating System',
    columnKey: 'operatingSystem',
    key: 'operatingSystem',
    width: 122
  },
  {
    name: 'Database List',
    title: 'Database List',
    columnKey: 'db_engine',
    key: 'db_engine',
    width: 122
  },
  {
    name: 'Region',
    title: 'Region',
    columnKey: 'region',
    key: 'region',
    width: 140
  },
  {
    name: 'Action',
    title: 'Action',
    columnKey: 'action',
    key: 'action',
    width: 80
  }
];
export const questionSubmitPayload = [{
  user_id: 1,
  customer_id: 123,
  data_id: [],
  created_by: 'Abhishek Abhinav',
  tabData: [{
    access_id: '1',
    question: []
  },
  {
    access_id: '2',
    question: []
  },
  {
    access_id: '3',
    question: []
  },
  {
    access_id: '4',
    question: []
  }]

}];


// Functional benefits Grid column list
export const functionalBenefitsColumnNames = [
  {
    name: '',
    title: '',
    columnKey: 'sideTitle',
    key: 'sideTitle',
    width: 130
  },
  {
    name: 'Adverse',
    title: 'Adverse',
    columnKey: 'adverse',
    key: 'adverse',
    width: 70
  },
  {
    name: 'No Impact/Neutral',
    title: 'No Impact/Neutral',
    columnKey: 'noImpactNeutral',
    key: 'noImpactNeutral',
    width: 130
  },
  {
    name: 'Marginal Positive',
    title: 'Marginal Positive',
    columnKey: 'marginalPositive',
    key: 'marginalPositive',
    width: 130
  },
  {
    name: 'Significant Positive',
    title: 'Significant Positive',
    columnKey: 'significantPositive',
    key: 'significantPositive',
    width: 130
  },
  {
    name: 'Transformative',
    title: 'Transformative',
    columnKey: 'transformative',
    key: 'transformative',
    width: 130
  }];

export const functionalLevel = [{
  level_1: 'Functionality',
  level_2: 'Automation',
  ques_id: 77
}, {
  level_1: 'Functionality',
  level_2: 'Analytics/Intelligence',
  ques_id: 78
}, {
  level_1: 'Functionality',
  level_2: 'Customization',
  ques_id: 79
}, {
  level_1: 'Process Transformation',
  level_2: 'Process Redesign',
  ques_id: 80
}, {
  level_1: 'Process Transformation',
  level_2: 'Controls',
  ques_id: 81
}, {
  level_1: 'Business Partnering',
  level_2: 'Time to Insight',
  ques_id: 82
}, {
  level_1: 'Business Partnering',
  level_2: 'Visibility',
  ques_id: 83
}, {
  level_1: 'Agility',
  level_2: 'Time to Serve',
  ques_id: 84
}, {
  level_1: 'Experience',
  level_2: 'Customer Experience',
  ques_id: 85
}, {
  level_1: 'Functionality',
  level_2: 'User Experience',
  ques_id: 86
}, {
  level_1: 'Risk & Controls',
  level_2: 'Controls',
  ques_id: 87
}];

export const customerListColumns = [
  {
    name: 'Customer Name',
    title: 'Customer Name',
    columnKey: 'customerName',
    key: 'customerName',
    width: 150
  },
  {
    name: 'Customer Contact',
    title: 'Customer Contact',
    columnKey: 'customerContact',
    key: 'customerContact',
    width: 120
  },
  {
    name: 'Customer Code',
    title: 'Customer Code',
    columnKey: 'customerCode',
    key: 'customerCode',
    width: 120
  },
  {
    name: 'Preferred Hypervisor',
    title: 'Preferred Hypervisor',
    columnKey: 'hypervisor',
    key: 'hypervisor',
    width: 120
  },
  {
    name: 'Multi Cloud',
    title: 'Multi Cloud',
    columnKey: 'isMultiCloud',
    key: 'isMultiCloud',
    width: 100
  },
  {
    name: 'AWS',
    title: 'AWS',
    columnKey: 'aws',
    key: 'aws',
    width: 60
  },
  {
    name: 'Azure',
    title: 'Azure',
    columnKey: 'azure',
    key: 'azure',
    width: 65
  },
  {
    name: 'GCP',
    title: 'GCP',
    columnKey: 'gcp',
    key: 'gcp',
    width: 60
  },
  {
    name: 'Oracle',
    title: 'Oracle',
    columnKey: 'oracle',
    key: 'oracle',
    width: 65
  },
  {
    name: 'Actions',
    title: 'Actions',
    columnKey: 'action',
    key: 'action',
    width: 70
  }
];

// Planning grid column names
export const planningGridColumnNames = [
  {
    name: '',
    title: '',
    columnKey: 'selector',
    key: 'selector',
    width: 40
  },
  {
    name: 'Action',
    title: 'Action',
    columnKey: 'action',
    key: 'action',
    width: 60
  },
  {
    name: 'Application',
    title: 'Application',
    columnKey: 'app_name',
    key: 'app_name',
    width: 100
  },
  {
    name: 'Priority Score',
    title: 'Priority Score',
    columnKey: 'score',
    key: 'score',
    width: 180
  },
  {
    name: 'Recommended Hyperscale',
    title: 'Recommended Hyperscale',
    columnKey: 'infrastructure_type',
    key: 'infrastructure_type',
    width: 180
  },
  {
    name: 'Hyperscaler Cost',
    title: 'Hyperscaler Cost',
    columnKey: 'total_cost',
    key: 'total_cost',
    width: 140
  },
  {
    name: 'Cloud Bucket',
    title: 'Cloud Bucket',
    columnKey: 'application_bucket',
    key: 'application_bucket',
    width: 140
  },
  {
    name: 'Wave',
    title: 'Wave',
    columnKey: 'wave',
    key: 'wave',
    width: 80
  },
  {
    name: 'Edit Wave',
    title: 'Edit Wave',
    columnKey: 'edit',
    key: 'edit',
    width: 80
  }
];
