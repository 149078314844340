import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CmtCustomerService {

  constructor(private http: HttpClient) {
  }

  // Get Customer List API //
  getCUstomerDetailsList(): Observable<any> {
    return this.http.get('customersetup/customerdetails');
  }

  // Add Or Update Customer Details API //
  addOrUpdateCustomerDetails(payload: any): Observable<any> {
    return this.http.post('customersetup/addorupdate/customer', payload);
  }

  // Delete Customer API //
  deleteCustomer(payload: any): Observable<any> {
    return this.http.post('customersetup/delete', payload);
  }
}
