<nav class="navbar navbar-default sticky">
  <div class="container-fluid d-flex p-0">
    <div class="navbar-header mr-auto">
      <div>
        <img style="
            height: 40px;
            border-right: 1px solid #e2e2e2;
            padding-right: 3px;
          " src="../../assets/images/CTS.png" alt="CTS (Carbon To Silicon)" />
        <img style="height: 40px; padding-left: 25px;" src="../../assets/images/genpact-logo.svg" alt="img" />
      </div>
    </div>
    <div>
      <div class="btn-group header-btn"
        *ngIf="userLoggedInDetails.role_id === 1 && userLoggedInDetails.customerId === 1">
        <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span><img src="../../assets/images/icons/settings.svg" alt="img" /></span>
        </span>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" type="button" (click)="openCustomersList()">Customers</a>
          <a class="dropdown-item" type="button">Tools</a>
        </div>
      </div>
      <div class="btn-group header-btn ml-1">
        <span class="user-img" [style.background-image]="backgroundImg"></span>
        <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- <img src="../../assets/images/user-1.svg" alt="img" width="18" /> -->
          <span class="ml-2 pr-3 userName"> {{ camelize(userName || "User name") }}</span>

        </span>
        <div class="dropdown-menu dropdown-menu-right" style="width: calc(100% + 20px);">
          <a class="dropdown-item" type="button" (click)="openUserProfileModal()">Profile</a>
          <a class="dropdown-item" type="button" (click)="logout()">Log out</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Customer Details Popup -->
  <ng-template #customerDetailModal>
    <div class="modal-header">
      <h4 class="modalHeadText">{{customerAction === 'add' ? 'Add' : 'Edit'}} Customer Details</h4>
      <div class="close pull-right" (click)="closeCustomerDetailModal()"><span class="cross" style="padding: 12px;
        font-weight: bold;">&times;</span></div>
    </div>
    <div class="modal-body">
      <div class="form-body">
        <!-- <span *ngIf="isAddServiceSpinnerLoading" class="spinner-loader gridSpinnerCss"></span> -->
        <form class="col-12" autocomplete="off" [formGroup]="customerDetailsForm">
          <div class="form-group row">
            <label for="customerName" class="col-4 col-form-label">Customer Name</label>
            <div class="col-8">
              <input type="text" class="form-control form-input customer-input" id="customerName" name="customerName"
                required placeholder="Enter Customer Name" autocomplete="off" formControlName="customerName">
            </div>
          </div>
          <div class="form-group row">
            <label for="customerContact" class="col-4 col-form-label">Customer Contact</label>
            <div class="col-8">
              <input type="text" class="form-control form-input customer-input" id="customerContact"
                name="customerContact" required placeholder="Enter Customer Contact" formControlName="customerContact">
            </div>
          </div>
          <div class="form-group row" *ngIf="customerAction === 'edit'">
            <label for="customerCode" class="col-4 col-form-label">Customer Code</label>
            <div class="col-8">
              <input type="text" class="form-control form-input customer-input" id="customerCode" name="customerCode"
                required placeholder="Customer Code" formControlName="customerCode">
            </div>
          </div>
          <div class="form-group row">
            <label for="multiCloud" class="col-4 col-form-label">Multi Cloud</label>
            <div class="col-8" style="padding: 13px 0px 0px 17px;">
              <label class="radio-inline">
                <input type="radio" id="multiCloud" name="multiCloud" value="Y" (change)="onMultiCloudChange($event)"
                  formControlName="multiCloud">
                <span style="padding-right: 20px;padding-left: 6px;">Yes</span>
              </label>
              <label class="radio-inline">
                <input type="radio" id="multiCloud" name="multiCloud" value="N" (change)="onMultiCloudChange($event)"
                  formControlName="multiCloud">
                <span style="padding-right: 20px;padding-left: 6px;">No</span>
              </label>
            </div>
          </div>
          <div class="form-group row" *ngIf="customerDetailsForm.controls.multiCloud.value === 'N'">
            <label for="platforms" class="col-4 col-form-label">Platforms</label>
            <div class="col-8">
              <angular2-multiselect [data]="availablePlatformsList" [settings]="settings1" [(ngModel)]="selectedItems"
                formControlName="platforms">
              </angular2-multiselect>
            </div>
          </div>
          <div class="form-group row" *ngIf="customerDetailsForm.controls.multiCloud.value === 'Y'">
            <label for="platforms" class="col-4 col-form-label">Platforms</label>
            <div class="col-8">
              <angular2-multiselect [data]="availablePlatformsList" [settings]="settings2" [(ngModel)]="selectedItems"
                formControlName="platforms">
              </angular2-multiselect>
            </div>
          </div>
          <div class="form-group row" *ngIf="customerDetailsForm.controls.multiCloud.value === 'Y'">
            <label for="preferredHypervisor" class="col-4 col-form-label">Preferred Hypervisor</label>
            <div class="col-8">
              <select id="preferredHypervisor" class="form-control inputCss" formControlName="preferredHypervisor"
                name="preferredHypervisor">
                <option value="" selected>Select Preferred Hypervisor</option>
                <option value="{{opt.id}}" *ngFor="let opt of availablePlatformsList">{{opt.itemName}}</option>
              </select>
              <!-- <input type="text" class="form-control form-input customer-input" id="preferredHypervisor"
                name="preferredHypervisor" placeholder="Enter Preferred Hypervisor"
                formControlName="preferredHypervisor"> -->
            </div>
          </div>
        </form>
      </div>
      <div class="modalFooter text-center">
        <button class="primary servicestButton customer-btn" [disabled]="!customerDetailsForm.valid"
          (click)="onCustomerDetailsSubmit()" *ngIf="customerAction === 'add'">Add</button>
        <button class="primary servicestButton customer-btn" [disabled]="!customerDetailsForm.valid"
          (click)="onCustomerDetailsUpdate()" *ngIf="customerAction === 'edit'">Update</button>
        <button class="secondary customer-btn" (click)="onCustomerDetailsClear()"
          *ngIf="customerAction === 'add'">Clear</button>
      </div>
    </div>
  </ng-template>


  <!-- Customer List popup -->
  <ng-template #customersListModal>
    <div class="modal-header" [ngClass]="{'reduceOpacity':reduceOpacity}">
      <h4 class="modalHeadText">Customers</h4>
      <div class="close pull-right" (click)="customerListModalRef.hide()"><span class="cross"
          style="padding: 12px; font-weight: bold;">&times;</span></div>
    </div>
    <div class="modal-body p-0" [ngClass]="{'reduceOpacity':reduceOpacity}">
      <div class="form-body">
        <div class="d-flex justify-content-between" style="padding: 10px 10px 0px 0px;"
          *ngIf="roleId === 1 && customerId === 1">
          <button class="primary" style="margin-top: 0;margin-left: 9px;" (click)="openCustomerModal('add')"
            [disabled]="isCustomerListLoading">
            <span style="color: #fff;">Add Customer</span></button>
        </div>
        <!-- Customer List Grid -->
        <div>
          <!-- Grid Loader -->
          <span *ngIf="isCustomerListLoading" class="spinner-loader gridSpinnerCss"></span>
          <div *ngIf="noCustomerData" class="noRecordsMssg">No Data Found</div>
          <ngx-datatable id="customerListTable" class="material fullscreen customerListTable" [rows]="customerRowsList"
            [columnMode]="'force'" [headerHeight]="38" [rowHeight]="36" [scrollbarV]="true" [scrollbarH]="true"
            [reorderable]="true" [swapColumns]="false" (page)="customerListOnScroll($event)" [swapColumns]="false"
            [summaryHeight]="35">
            <ng-container *ngFor="let customer of customerGridColumns">
              <ngx-datatable-column name="{{customer.title}}" prop="{{customer.key}}" [draggable]="false"
                [width]="customer.width">
                <ng-template let-column="column" let-sort="sortFn" let-sortDir="sortDir" ngx-datatable-header-template>
                  <span class="curPointer draggable headerCss gridSortOrder"
                    style="background-color: #fff;color: #313131;" title="{{customer.title}}"
                    (click)="onCustomerListGridColumnSort(customer.columnKey)">{{customer.title}}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" let-sort="sortFn"
                  ngx-datatable-cell-template>
                  <span *ngIf="customer.key === 'action'" class="actionIcon">
                    <!-- <img src="../../../assets/images/icons/deleteNew.svg" alt="img" style="padding-right: 14px;"
                      (click)="confirmDelete(row.customerId)"> -->
                    <img src="../../../assets/images/icons/editNew.svg" alt="img"
                      *ngIf="userLoggedInDetails.role_id === 1 && userLoggedInDetails.customerId === 1"
                      (click)="editCustomerDetails(row, 'edit')" title="Edit"></span>
                  <span
                    title="{{value === true ? 'Yes' : value === false ? 'No' : value}}">{{value === true ? 'Yes' : value === false ? 'No' : value}}</span>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>
          </ngx-datatable>
        </div>
      </div>
      <div class="modalFooter d-flex justify-content-end">
        <span class="textColor">Total No. of Customers: {{customerRowsList.length}}</span>
      </div>
    </div>
  </ng-template>

  <!-- Delete Confirmation Modal -->
  <ng-template #deleteCustomerModal>
    <div class="modal-header">
      <h4 class="modalHeadText">Confirmation</h4>
      <div class="close pull-right" (click)="closeDeleteModal();"><span class="cross" style="padding: 12px;
        font-weight: bold;">&times;</span></div>
    </div>
    <div class="modal-body">
      <p class="confirmationText">Are you sure to delete customer?</p>
      <div class="modalFooter text-center">
        <button class="primary servicestButton customer-btn" (click)="onCustomerDelete()">Yes</button>
        <button class="secondary customer-btn" (click)="closeDeleteModal()">No</button>
      </div>
    </div>
  </ng-template>

  <ng-template #userProfileModal>
    <div class="modal-header">
      <h4 class="modalHeadText">Profile Information</h4>
      <!-- <div class="close pull-right" (click)="userProfileModalRef.hide();"><span class="cross" style="padding: 12px;
        font-weight: bold;">&times;</span></div> -->
    </div>
    <div class="modal-body user-model-body">
      <!-- <div class="row">
        <div class="col-sm-5 col-xs-6 profile-title">First Name:</div>
        <div class="col-sm-7 col-xs-6">Venkata</div>
        <div class="clearfix"></div>
        <div class="bot-border"></div>

        <div class="col-sm-5 col-xs-6 profile-title">Last Name:</div>
        <div class="col-sm-7"> Manikanta</div>
        <div class="clearfix"></div>
        <div class="bot-border"></div>

        <div class="col-sm-5 col-xs-6 profile-title">Email Address:</div>
        <div class="col-sm-7">venkatamanikanta.boddu@genpact.com</div>
        <div class="clearfix"></div>
        <div class="bot-border"></div>

        <div class="col-sm-5 col-xs-6 profile-title">Role:</div>
        <div class="col-sm-7">Admin</div>
        <div class="clearfix"></div>
        <div class="bot-border"></div>

        <div class="col-sm-5 col-xs-6 profile-title">Status</div>
        <div class="col-sm-7">Active</div>
      </div> -->
      <table class="table table-user-information">
        <tbody>
          <tr>
            <td class="profile-title">First Name:</td>
            <td class="profile-value">{{firstName}}</td>
          </tr>
          <tr>
            <td class="profile-title">Last Name:</td>
            <td class="profile-value">{{lastName}}</td>
          </tr>
          <tr>
            <td class="profile-title">Email:</td>
            <td class="profile-value">{{userLoggedInDetails.userEmail}}</td>
          </tr>
          <tr>
            <td class="profile-title">Role:</td>
            <td class="profile-value">{{userLoggedInDetails.role_id === 1 ? 'Admin' : 'Reader'}}</td>
          </tr>
          <tr>
            <td class="profile-title">Status:</td>
            <td class="profile-value">{{userLoggedInDetails.status === true ? 'Active' : 'Inactive'}}</td>
          </tr>
          <tr>
            <td class="profile-title">Customer Name:</td>
            <td class="profile-value">{{userLoggedInDetails.customerName}}</td>
          </tr>
        </tbody>
      </table>
      <div class="modalFooter text-center">
        <button class="secondary customer-btn" (click)="userProfileModalRef.hide()">Close</button>
      </div>
    </div>
  </ng-template>
</nav>