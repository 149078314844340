import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserAuthenticationGuard } from './user-authentication.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'signup',
        pathMatch: 'full'
    },
    // {
    //     path: 'signup',
    //     loadChildren: () => import('../Modules/signup/signup.module').then(mod => mod.SignupModule),
    // },
    // {
    //     path: 'app-inventory',
    //     loadChildren: () => import('../Modules/app-invertory/app-invertory.module').then(mod => mod.AppInvertoryModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'assessment',
    //     loadChildren: () => import('../Modules/assessment/assessment.module').then(mod => mod.AssessmentModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'report-out',
    //     loadChildren: () => import('../Modules/report-out/report-out.module').then(mod => mod.ReportOutModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'cost-calculation',
    //     loadChildren: () => import('../Modules/cost-calculation/cost-calculation.module').then(mod => mod.CostCalculationModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'effort-estimation',
    //     loadChildren: () => import('../Modules/effort-estimation/effort-estimation.module').then(mod => mod.EffortEstimationModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'planning',
    //     loadChildren: () => import('../Modules/planning/planning.module').then(mod => mod.PlanningModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'plan',
    //     loadChildren: () => import('../Modules/planning/planning.module').then(mod => mod.PlanningModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'execution-tracking',
    //     loadChildren: () => import('../Modules/execution-tracking/execution-tracking.module').then(mod => mod.ExecutionTrackingModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    // {
    //     path: 'billing',
    //     loadChildren: () => import('../Modules/billing/billing.module').then(mod => mod.BillingModule),
    //     canLoad: [UserAuthenticationGuard]
    // },
    {
        path: 'signup',
        loadChildren: () => import('../Modules/signup-tsizing/signup-tsizing.module').then(mod => mod.SignupTsizingModule),
    },
    {
        path: 'estimate',
        loadChildren: () => import('../Modules/t-sizing/t-sizing.module').then(mod => mod.TSizingModule),
    },
    {
        path: '**',
        redirectTo: 'signup',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
