<!-- Application Header -->
<!-- <app-cmt-header></app-cmt-header> -->

<!-- SideNavbar With Menus -->
<!-- <div class="sidenav">
  <div class="sidenav-menu-holder">
    <nav>
      <ul class="curPointer mainMenus">
        <li *ngFor="let navbar of sideNavBarData" [ngClass]="{'disableNavBars':navbar.disabled}">
          <a class="d-flex" title="{{navbar.pageName}}" routerLink="{{navbar.path}}" routerLinkActive="active"
            (click)="onClickSideNavMenu()">
            <span class="imgIcon">
              <img *ngIf="navbar.path === 'app-inventory'" src="../../assets/images/icons/upload.svg" alt="img">
              <img *ngIf="navbar.path === 'assessment'" src="../../assets/images/icons/process.svg" alt="img">
              <img *ngIf="navbar.path === 'report-out'" src="../../assets/images/icons/inventory.svg" alt="img">
              <img *ngIf="navbar.path === 'cost-calculation'" src="../../assets/images/icons/schedule-1.svg" alt="img">
              <img *ngIf="navbar.path === 'effort-estimation'" src="../../assets/images/icons/schedule-1.svg" alt="img">
              <img *ngIf="navbar.path === 'planning'" src="../../assets/images/icons/zoom-in.svg" alt="img">
              <img *ngIf="navbar.path === 'plan'" src="../../assets/images/icons/zoom-in.svg" alt="img">
              <img *ngIf="navbar.path === 'execution-tracking'" src="../../assets/images/icons/center-1.svg" alt="img">
              <img *ngIf="navbar.path === 'billing'" src="../../assets/images/icons/catalogue.svg" alt="img">
            </span>
            <span class="imgIconText">{{navbar.pageName}}</span>
          </a>
        </li>
      </ul> -->
      <!-- Settings Menus -->
      <!-- <ul class="curPointer bottomMenu collapse show">
        <li *ngFor="let settingsnavbar of sideNavBarSettingsData"
          [ngClass]="{'disableNavBars':settingsnavbar.disabled}">
          <a class="d-flex" title="{{settingsnavbar.label}}" *ngIf="settingsnavbar.subMenu" data-toggle="collapse"
            href="#{{settingsnavbar.id}}" [ngClass]="{'has-dropdown': settingsnavbar.subMenu}"
            [attr.aria-expanded]="openSubMenuFlag">
            <img *ngIf="settingsnavbar.path === 'cmt_settings'" src="../../assets/images/settings.png" alt="img">
            <span style="padding-left: 12px;">{{settingsnavbar.label}}</span>
          </a>

          <ul *ngIf="settingsnavbar.subMenu" class="collapse" id="{{settingsnavbar.id}}"
            [ngClass]="{'show':openSubMenuFlag}">
            <li *ngFor="let subMenu of settingsnavbar.subMenu" [ngClass]="{'disableNavBars':subMenu.disabled}">
              <a class="d-flex" title="{{subMenu.label}}" data-toggle="collapse" [attr.data-target]="'#' + subMenu.id"
                routerLink="{{subMenu.path}}" routerLinkActive="active" [attr.aria-expanded]="openSubMenuFlag"
                style="padding-left: 47px;" (click)="onClickSideNavMenu()">
                <img *ngIf="subMenu.path === 'customer'" src="../../assets/images/customer.png" alt="img">
                <img *ngIf="subMenu.path === 'tool'" src="../../assets/images/tool.png" alt="img">
                <span style="padding-left: 12px;">{{subMenu.label}}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul> -->
    <!-- </nav>
  </div>
</div> -->

<!-- Main Page Content -->
<div class="main">
  <router-outlet></router-outlet>
  <app-cmt-footer></app-cmt-footer>
</div>