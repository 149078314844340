import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { AppComponent } from './app.component';
import { CmtHeaderComponent } from '../Components/cmt-header/cmt-header.component';
import { CmtFooterComponent } from '../Components/cmt-footer/cmt-footer.component';
import { CmtSideNavbarComponent } from '../Components/cmt-side-navbar/cmt-side-navbar.component';
import { AppRoutingModule } from 'src/Common/app-routing-module';
import { SharedModule } from 'src/Common/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    CmtHeaderComponent,
    CmtFooterComponent,
    CmtSideNavbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    AngularMultiSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
